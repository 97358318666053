@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins';
@import "../../App.scss";

$mt_font: rgb(255, 147, 135);
$swe_font: $light-blue-link;

#home{
    margin-bottom: 3rem;
    margin-top: 2rem;
    position: relative;
}

.hero-container{
    background-image: url(../../assets/typography-2.gif);
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: rgb(30, 30, 30);
    min-height: 80vh;

    @media screen and (max-width: 600px) {
        background-position: 26%;
    }

    //1080 depends on the size of future gif/3D Model.
    @media screen and (min-width: 1700px) {
        background-size: contain;
      }

    h1,
    h3,
    p{
        text-shadow: 3px 5px 10px rgba(0,0,0, 1);
    }

    .mt_font{
        color: $mt_font;
    }

    .swe_font{
        color: $swe_font;
    }
}

.title{
    font-size: 7rem;
    font-weight: 800;
}

.subtitle{
    font-size: 2rem;
}

.introText_container{

    margin-top: 8rem;
    max-width: 700px;
    .introText{
        font-size: 1.5rem;
        font-weight: 300;
    }
}

@include media-breakpoint-down(sm){
    .title{
        font-size: 4rem;
    }
    
    .subtitle{
        font-size: 1.6rem;
    }

    .introText_container{
        margin-top: 3rem;
        .introText{
            font-size: 1.2rem;
        }
    }
}

#scroll_prompt_container{
    align-self: center !important;
    background-color: red;
    width: 4rem;
    position: absolute;
    bottom: 0;
}