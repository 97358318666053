@import "../../App.scss";
@import "../achievements/Achievements.scss";

$footer-left: #191919;
$footer-right: rgb(18, 18, 18);

@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins';

#socials{
    height: 40rem;
    width: 100%;
}

.socials_container{
   background-color: $footer-left;

    h2{
        font-weight: 200;
    }

    ul{
        padding: 0;
    }

    li{
        list-style: none;
        
        &:hover{
            a{
                color: $light-blue-link;
            }
            transform: translateX(10px);
            .social_icon{
                *{
                    color:$light-blue-link;
                }
            }
        }
    }

    a{
        text-decoration: none;
        color: white;
        font-weight: 200;
        font-size: 1.5rem;

        .social_icon{
            max-width: 24px;
        }
    }

    @include media-breakpoint-down(md){
        height: 30rem !important;
    }

    @include media-breakpoint-down(sm){
        height: 26rem !important;
    }

}

.second_container{
    background-color:$footer-right;

    .profile-message_image-container{
        border-radius: 100%;
        overflow: hidden;
        width: 10rem;
        height: 10rem;
        
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        @include media-breakpoint-down(xxl){
            margin-bottom: 2rem;
        }

        @include media-breakpoint-down(sm){
            width: 8rem;
            height: 8rem;
            margin-bottom: 2rem;
        }
    }

    .profile-message_message{
        max-width: 30rem;
        font-size: 1.2rem;

        @include media-breakpoint-down(sm){
           margin: 1rem 0 2rem 0!important;
        }
    }

    .typography{
        width:100%;
        max-width: 30rem;
    }

    p{
        font-weight: 200;
    }

    @include media-breakpoint-down(md){
        height: 40rem !important;
    }
   
}

.footer-containers_sizing{
    height: 100%;

    @include media-breakpoint-down(sm){
        padding: 4rem 2rem !important;
    }

    @include media-breakpoint-up(md){
        padding-top: 5rem !important;
    }

    @include media-breakpoint-up(xl){
        padding-left: 7rem !important;
    }

    @include media-breakpoint-up(xxl){
        padding-left: 8% !important;
    }
}