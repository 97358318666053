@import '../../App.scss';

@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins';


@keyframes sizeIncrease {
    0%{scale: 100%;}
    50% { 
        scale: 120%;}
    100%{ 
        scale: 100%;}
}

@keyframes glowingLogo {
    0%{
        text-shadow: 0 0px 5px rgba($color: $light-blue-link, $alpha: 1.0);
    }
    50%{
        text-shadow: 0 0px 20px rgba($color: $light-blue-link, $alpha: 1.0);
    }
    100%{
        text-shadow: 0 0px 5px rgba($color: $light-blue-link, $alpha: 1.0);
    }
}

.brand-logo{
    text-decoration: none;
    font-size: 1.6rem;
    font-weight: 300;
    color: white;
    text-shadow: 0 0px 5px rgba($color: $light-blue, $alpha: 1.0);
    animation-name: glowingLogo;
    animation-duration: 3s;
    animation-iteration-count: infinite;

        &:hover{
            color: $light-blue-link;
            animation-name: sizeIncrease;
            animation-duration: 4s;
            animation-iteration-count: infinite;
        }
    
}


.nav-link{
    font-size: 1.1rem !important;
    font-weight: 300 !important;
    color: white !important;
    
  
    @include media-breakpoint-up(lg){

        padding: 0 1.2rem !important;

        &:hover{
            transform: scale(120%) translateY(-2px);
            text-shadow: 0px 3px 5px rgba(0,0,0, 1);
            color: $light-blue-link !important;
          }
    }
    

    @include media-breakpoint-down(lg){

        padding: 0.8rem 0;

        &:hover{
            transform: scale(105%);
            text-shadow: 0px 3px 5px rgba(0,0,0, 1);
            color: $light-blue-link !important;
          }
    }

}

#navbarSupportedContent{
    @include media-breakpoint-down(lg){
        padding-top: 1.5rem;
    }
}