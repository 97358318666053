@import "../../App.scss";
$blue-shade: rgb(49, 80, 149);

$accordion-button-inactive: #34353a;
$accordion-panel: #2b3448;


#experience{
    margin-bottom: 3rem;
}

.accordionButton{
    display:block;
    width: 100%;
    border-radius: 5px;
    border: none;
    color: white;
    text-align: left;
    font-size: 1.4rem;
    font-weight: 300;

    &.--btn-active{
        transition: 0.6s;
        background-color: $light-blue;
        box-shadow: inset 0 0 10px 0px rgba($color: $blue-shade, $alpha: 0.5);   
    }

    &.--btn-inactive{
        transition: 0.2s;
        background-color: $accordion-button-inactive;
    }
}

@keyframes openingIcon {
    from {
        rotate: 0deg;
    } to {
        rotate: 180deg;
    }
}

@keyframes closingIcon {
    from {
        rotate: 180deg;
    } to {
        rotate: 360deg;
    }
}

.--btn-active-icon{
        rotate: 0deg;
        animation-name: openingIcon;
        animation-duration: 0.5s;
        animation-fill-mode: forwards; 
}

.--btn-inactive-icon{
    rotate: 180deg;
    animation-name: closingIcon;
    animation-duration: 0.5s;
    animation-fill-mode: forwards; 
}



.accordionPanel{
    display: block;
    width: 100%;
    background-color: rgba($color: $accordion-panel, $alpha: 0.5);
    min-height: 10rem;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(black, 0.5);
    font-size: 1.2rem;
    font-weight: 300;

    .companyLogo{
        max-width: 70%;
        max-height: 180px;

        @include media-breakpoint-up(md){
            max-width: 100%;
        }
    }

    &.--showAccordion{
        animation-name: panelFadeIn;
        animation-duration: 0.6s;
        animation-fill-mode: forwards;
    }

    &.--hideAccordion{
        display: none !important;
    }
}

@keyframes panelFadeIn {
    from {
        opacity: 50%;
        translate: 0 -4rem;
    } to {
        opacity: 100%;
        translate: 0%;
    }
    
}
  