@import "../expertise/Expertise.scss";
@import "../experience/Experience.scss";
@import "../../App.scss";

$microsoft-colour: rgb(59, 129, 250);
$aws-colour: rgb(255, 140, 40);
$oracle-colour: rgb(194, 43, 43);
$default-white: rgb(229, 229, 229);


@keyframes linkArrowSlide {
    0%{
        transform: translateX(-2rem);
    }
    50%{
        transform: translateX(0px);
    }
    100%{
        transform: translateX(2px);
    }
}

.eventBlock_link{
    text-decoration: none;
    font-size: 1.2rem;

    &.--link_active:hover{
        .eventBlock_container{
            transform: translateY(-10px);
            transition-duration: 0.2s;
            box-shadow: 0 5px 10px 4px rgba($color: #000000, $alpha: 0.5);

            .--event-title{
                color: $light-blue-link !important;
            }
        }

        .--show_icon{
            animation-name: linkArrowSlide;
            animation-duration: 0.3s;
            animation-timing-function: linear;
            animation-fill-mode: forwards;

            transform: scale(130%);

            *{
                color: $light-blue-link;
            }
        }
    }
}

.eventBlock_motionContainer{
    height: 100%;
}

.eventBlock_container{
    background-color: $dark-grey-panel;
    border-radius: 0.3rem;
    height: 100%;
    position: relative;

    .--event-title{
        font-size: 1.2rem;
    }
    
    .--event-date{
        font-size: 0.9rem;
        font-weight: 300;
    }

    .icon_container{
    //    width: 3.2rem !important;
    }

    .--show_icon{
        display: block;
    }

    .--hide_icon{
        display: none;
    }

    .--firstPlace{
        *{
            color: $expert-gold;
        }
    }
    
    .--secondPlace{
        *{
            color: $proficient-silver;
        }
    
    }
    
    .--thirdPlace{
        *{
            color: $intermediate-bronze;
        }
    }
    
    .--completion{
        *{
            color: $default-white;
        }
    }
    
    .--certification{
        *{
            color: $softSkills-green;
        }
    }

    .--cloud{
        *{
            color: $default-white;
        }

        &.--microsoft{
            *{
                color: $microsoft-colour;
            }
        }

        &.--aws{
            *{
                color: $aws-colour;
            }
        }

        &.--oracle{
            *{
                color: $oracle-colour;
            }

        }
    }

    .--education{
        *{
            color: $softSkills-green;
        }

    }

    
}



