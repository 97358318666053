
@import "../../App.scss";

$small-button-size: 4rem;
$mobile-bottom: 20px;
$other-bottom: 3rem;

@keyframes showBtn_mobile{
  0%{
   bottom: 4rem;
  } 100%{
    bottom: 1rem;
  }
}

@keyframes showBtn_others{
  0%{
   bottom: 5rem;
  } 100%{
    bottom: 3rem;
  }
}

.backToTopButton{
  visibility: hidden;
  position: fixed;
  z-index: 99; 
  border: none;
  outline: none; 
  background-color: rgba(0,0,0,0.3);
  font-size: 1.5rem;
  border-radius: 50%; 
 
  width: $small-button-size;
  height: $small-button-size;
  bottom: 3rem;
  right: 3rem; 

  .backToTop_icon{
    *{
        color: grey;
    }
  }

  @include media-breakpoint-down(sm){
    bottom: 1.2rem;
    right: 1rem; 
    width: 3rem;
    height: 3rem;
  }

  @include media-breakpoint-up(lg){
    bottom: 3rem;
    right: 3rem; 
  }

  &.--display_btn{
    visibility: visible;
   
    animation-duration: 1s;
    animation-fill-mode: forwards;

    @include media-breakpoint-down(sm){
      animation-name: showBtn_mobile;
    }

    @include media-breakpoint-up(sm){
      animation-name: showBtn_others;
    }
  }

  &:hover{
    background-color: rgba(white, 0.3);
    box-shadow: 0px 6px 10px rgba(0,0,0,0.5);

    .backToTop_icon{
        *{
            color: white; /* Text color */
        }
      }
  }

  &:focus,
  &:active{
    background-color: black;
  }

 
}

