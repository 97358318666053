$dark-grey-panel: #2e2e2e;
$expert-gold: rgb(221, 174, 54);
$expert-gold-dark: rgb(180, 133, 14);
$proficient-silver: rgb(170, 170, 170);
$proficient-silver-dark: rgb(131, 131, 131);
$intermediate-bronze: rgb(180, 92, 48);
$intermediate-bronze-dark: rgb(147, 76, 41);
$softSkills-purple: rgb(189, 109, 211);
$softSkills-green: rgb(111, 190, 176);

$container-heading-size: 2rem;

@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins';

@keyframes textGlow {
    0%{
        text-shadow: 0 0 3px rgba($color: rgb(252, 251, 168), $alpha: 1.0);;
    } 
    50% {
        text-shadow: 0 0 13px;
    }
    75%{
        text-shadow: 0 0 13px;
    }
    100%{
        text-shadow: 0 0 3px;
    }
    
}

#expertise{
    margin-bottom: 3rem;
}

.expertiseContainer{
    border-radius: 10px;
    background-color: rgba($dark-grey-panel, 1);
    height: 100%;
    text-align: left;
    padding: 0.5rem 1rem;

    @include media-breakpoint-down(md){
        padding: 1rem 2.5em; 
    }

    @include media-breakpoint-down(sm){
        padding: 1rem 1.5em; 
    }

    h3,
    h4,
    p{
        font-weight: 300;
    }

    h3{
        font-size: $container-heading-size;
    }

    p{
        text-align: left;
        width: 100%;
        font-size: 1.2rem;
    }

    img{
        width: 70%;
        max-width: 200px;
    }

    .--description-highlight{
        animation-name: textGlow;
        animation-duration: 2.5s;
        font-weight: 400;
        animation-iteration-count: infinite;
    }

}

.skillContainer{
    background-color: rgba($dark-grey-panel, 0.6);
    width: 100%;
    height: 100%;
    border-radius: 10px;
    font-size: 1.2rem;

    .heading{
        text-align: left;
        font-weight: 300;
        font-size: $container-heading-size;
        
    }

    &.--expert{

        .pillContainer{
            background-image: linear-gradient(to right, $expert-gold, $expert-gold-dark);
        }
    }
    &.--proficient{

        .pillContainer{
            background-image: linear-gradient(to right, $proficient-silver, $proficient-silver-dark);
        }
    }

    &.--intermediate{
    
        .pillContainer{
            background-image: linear-gradient(to right, $intermediate-bronze, $intermediate-bronze-dark);
        }
    }

    &.--softSkills{
    
        .pillContainer{
            background-image: linear-gradient(to right, $softSkills-purple, $softSkills-green);
        }
    }
    
    &:hover{
        .pillContainer{

            &:before{
                transform: translateX(250%);
            }
        }
    }

  
}


.pillContainer{
    position: relative;
    border-radius: 25px;
    background-color: rgba($color: black, $alpha: 0.2);
    min-width: 1rem;
    overflow: hidden !important;


    &:before{
        content: '';
        display: block;
        position: absolute;
        width: 100px;
        height: 100%;
        background: rgba(255, 255, 255, 0.5);
        filter: blur(10px);
        transform: translateX(-200%) skewX(-30deg);
        transition: 0.6s;
        
    }

    &:hover:before{
        transform: translateX(200%)
    }
}