@import "../expertise/Expertise.scss";
@import "../experience/Experience.scss";
@import "../achievements/Achievements.scss";

$filter-background: rgb(66, 66, 73);
$option-selected: rgb(42, 54, 165);


@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins';

@keyframes hoverEnlarge {
    from{
        transform: translatey(0px);
    } to {
        transform: translatey(-1.5rem) scale(102%);
    }
}

#myWork{
  margin-bottom: 5rem !important;

  label{
    font-weight: 200;
    
    @include media-breakpoint-down(sm){
        margin-left: 0rem !important;
    }
}
}

.projectBlock{
    border-radius: 15px;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 22rem;
    background-color: $dark-grey-panel;

    img{
        position: absolute;
        width: 100%;
        height: 75%;
        object-fit: cover;
        left: 0;
        top: 0;
    }

    .projectBlock_panel{
        position:absolute;
        height: 25%;
        width:100%;
        background-color: rgba($dark-grey-panel, 0.8);
        bottom: 0px;
        font-size: 1.1rem;
        font-weight: 300;
        text-align: left;

        .projectBlock_name{
            width: 90%;
        }

        .projectBlock_arrow{
            visibility: hidden;
            transform: translateX(-1.5rem);
            *{
                color: $light-blue-link;
            }
        }
    }

    &:hover{
        animation-name: hoverEnlarge;
        animation-duration: 0.2s;
        animation-timing-function: linear;
        animation-fill-mode: forwards;
        box-shadow: 0px 10px 20px 10px rgba(black, 0.6);

        .projectBlock_name{
            color: $light-blue-link;
        }

        .projectBlock_arrow{
                visibility: visible;
                transform: translateX(0px);
                transition-duration: 0.4s;
        }
    }

    @include media-breakpoint-up(lg){
        height: 18rem;
    }

    @include media-breakpoint-down(sm){
        height: 18rem;
    }
}

.featured_badge{
    display: none;
    position: absolute;
    background-color: $light-blue;
    box-shadow: inset 0 0 10px 0px rgba($color: $light-blue-link, $alpha: 0.8), 0 0 12px 1px rgba($color: black, $alpha: 0.6); 
    border: 1px solid $light-blue;
    color: white;
    border-radius: 2px;
    right: 0;
    top: 0;
    font-weight: 300;
    font-size: 1.1rem;
    z-index: 2;

    &.--isFeatured{
        display: block;
    }
}

#projectFilter{
    color: white;
    background-color: $filter-background;
    border-radius: 10px;
    border: none;
    outline: none;

    &:focus{
        outline: black;
    }
    
    option{
        font-weight: 200;
        color: white;
        border-bottom: 1px solid rgba($color: black, $alpha: 0.5);

        &:checked{
            background-color: $light-blue;
        }
    }
}

