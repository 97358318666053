@import "../../../App.scss";
@import "../../../pages/WorkShowcasePage.scss";

.visitProjectButton_container{
  width: 150px;

  a{
    text-decoration: none;
  }

  .visitProjectButton{
    background-color: transparent;
    border: 1px solid white;
    color: $white;
    border-radius: 0.5rem;
    width: 100%;
  }

  // Copied to WorkShowcasePage
  &:hover{
    @include visitProjectButtonHover();
  }
}


@keyframes arrowSlideRight {
  0%{
    transform: translateX(-1rem);
  }

  100%{
    transform: translateX(0rem);
  } 
}