@import url('https://fonts.googleapis.com/css2?family=Anonymous+Pro&family=Inconsolata&family=Jost:wght@200;300;400&display=swap');
$jost: jost, sans-serif;
$bg-primary: rgb(30,30,30);
$light-blue-link: rgb(86, 199, 255);
$light-blue: rgb(77, 143, 190);
$light-blue-select: rgb(69, 103, 150);

@import '../node_modules/bootstrap/scss/functions';
@import '../node_modules/bootstrap/scss/variables';
@import '../node_modules/bootstrap/scss/mixins';


* {
  box-sizing: border-box;
  color: white;
  font-family: $jost;
} 

*::selection{
  background-color: $light-blue-select;
}

// .border-blue{
//   border: 2px blue solid;
// }

// .border-red{
//   border: 2px red solid;
// }

// .border-green{
//   border: 2px green solid;
// }

h1,
h2,
p,
li{
  font-family: $jost;
}

html,
body,
.backgorund-primary{
  background-color: $bg-primary;
}



.section-heading{
  font-size: 3rem;
  font-weight: 200;
  margin-bottom: 2rem;
}

// TODO: Variable width for different screen sizes
.container{
  max-width: 1000px !important;

  @include media-breakpoint-down(lg){
    max-width: 80% !important;
  }

  @include media-breakpoint-down(sm){
    max-width: 90% !important;
  }
  
}

.inner-container{
  width: 100%;

  .row{
    width: 100% !important;
    margin: auto;
  }

}


.--section-margin-lg{
  @include media-breakpoint-up(md){
    margin-bottom: 10rem !important;
  }

  @include media-breakpoint-down(md){
    margin-bottom: 7rem !important;
  }
}

@keyframes wipe-enter {
	0% {
		transform: translateY(8rem);
	}
	50% {
		transform: translateY(0rem);
	}
}

@media (prefers-reduced-motion: no-preference) {
    .entrance{
        // visibility: hidden;
    }
    
    .entrance-animation {
      animation: wipe-enter 2s 1 !important;
    }
  }

  .error-link{
    text-decoration: none;
    color: $light-blue-link;

    &:hover{
      color: $light-blue;
    }
  }