@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/mixins';
@import "../App.scss";

@mixin visitProjectButtonHover(){
    .visitProjectButton{
        color: $light-blue-link;
        transform: scale(110%);
        transition-duration: 0.2s;
        border-color: $light-blue-link;
      }
  
      .icon {
        * {
          color: $light-blue-link;
        }
  
        animation-name: arrowSlideRight;
        animation-duration: 0.2s;
        animation-timing-function: linear;
        animation-fill-mode: forwards;
      }
}

$panel-block: rgb(37, 37, 37);


.workShowcasePage{
    
    h1{
        font-size: 3rem;
        margin-bottom: 5rem !important;
        word-wrap: break-word;

        @include media-breakpoint-down(md){
            margin-bottom: 4rem !important;
        }

        @include media-breakpoint-down(sm){
            font-size: 2.4rem;
        }
    }

    p, li{
        font-weight: 300;
        font-size: 1.1rem;
    }

    img{
        width: 100%;
    }

    .work-category{
        font-weight: 300;
    }

    .client_container{
        padding-left: 6%;

        @include media-breakpoint-down(md){
            padding-left: 0.8rem;
            margin-bottom: 2rem;
        }

        h5{
            text-decoration: underline;
            text-decoration-color: $light-blue;
            text-decoration-thickness: 4px;
        }
    }

    .pill_container{
        background-color: $panel-block;
        border-radius: 0.6rem;
        height: 100%;
    }

    .image_and_button_container{
        .title_image_link:hover + .visitProjectButton_container{

            // Copied from visitProjectButton.scss
            @include visitProjectButtonHover();
        }
    }
}

.empty{}